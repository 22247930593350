
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "approvalStep",
  props: {
    stepList: Array,
  },
  data() {
    return {};
  },

  methods: {
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
  },
});
