
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import regExps from "@/utils/regExps";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import TargetCards from "@/components/ListCard/TargetCards.vue";
import ApprovalStep from "@/components/attendance/approvalStep.vue";
import { VacationLeaveService } from "@/services/attendance/leave/VacationLeaveService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
export default defineComponent({
  name: "leaveInfo",
  components: { Header, TaskCards, TargetCards, ApprovalStep },

  setup() {
    const router = useRouter();
    const query = useRoute().query;
    const api = new VacationLeaveService();
    const WorkflowApi = new WorkflowService();
    let isMore = ref(false);
    const approval = ref({ attachments: [] });
    const stepList = ref(Array);
    let title = ref("请假审批");
    let handleType = ref();
    let userInfo = SystemUtils.loginUser;
    let canRollBack = ref();
    let btnIconRight = reactive(["icon63huanyuan"]);
    let btnSizeRight = reactive(["34px"]);
    const initData = () => {
      if (query) {
        handleType.value = query.handleType;
        if (query.typeLeave == "199") {
          title.value = "补假申请";
        }
        getInfo();
        getTaskList();
        getDeal();
        if (handleType.value != 0) {
          title.value = "请假详情";
        }
      }
    };
    //获取假期详情
    const getInfo = () => {
      let params = {
        id: query.id,
      };
      api.getInfo(params).then((res: any) => {
        if (res.userId == userInfo.id && res.approveStatus == 0) {
          canStopHandle();
        }
        approval.value = res;
        for (let pic of approval.value.attachments) {
            pic = Object.assign(pic, { isImage: true, url: pic['path'] });
        }
      });
    };
    //获取审批流程
    const getTaskList = () => {
      let params = {
        procId: query.procId,
      };
      WorkflowApi.getTaskList(params).then((res: any) => {
        stepList.value = res;
      });
    };
    //是否显示审批按钮
    const getDeal = () => {
      let params = {
        procId: query.procId,
        taskId: query.taskId,
      };
      WorkflowApi.isDealDoneWorkTask(params).then((res: any) => {
        if (res) {
          handleType.value = 2;
        }
      });
    };
    //是否可撤回
    const canStopHandle = () => {
      let params = {
        procId: query.procId,
      };
      WorkflowApi.canRollBackWorkTask(params).then((res: any) => {
        console.log(res);
        canRollBack.value = res;
      });
    };
    //撤回
    const onFiltClick = () => {
      if (!query.procId) {
        Utils.presentToastWarning("参数异常");
        return;
      }
      let params = {
        procId: query.procId,
      };
      Utils.confirmDialog("撤回", "请确认是撤回请假申请", function () {
        WorkflowApi.forceStopWorkTask(params)
          .then((res: any) => {
            Utils.presentToastWarning("已成功撤回！");
            router.go(-1);
          })
          .catch(() => {
            Utils.presentToastWarning("撤回失败！");
          });
      });
    };
    const subStringUserName = (userName: string) => {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    };
    //审批同意
    const agreeHandle = () => {
      Utils.confirmDialog("同意", "请确认是同意请假申请", function () {
        submitHandle(1);
      });
    };
    let refuseReason = ref();
    //审批不同意
    const refuseHandle = () => {
      Utils.confirmDialog(
        "不同意",
        "<div><textarea style='height:50px;' class='alert-textarea' id='refuseReason' placeholder='请输入不同意原因' rows='8' maxlength='100' :required='true'></textarea></div>",
        function () {
          let textarea = document.getElementById(
            "refuseReason"
          ) as HTMLTextAreaElement;
          let val = textarea.value;
          if (val.replace(/\s+/g, "") == "") {
            Utils.presentToastWarning("请输入不同意原因");
            return;
          }
          if (regExps.SpecChar.test(val)) {
            Utils.presentToastWarning("不同意原因不能输入特殊字符哦！");
            return;
          }
          refuseReason.value = val;
          submitHandle(2);
        }
      );
    };
    const submitHandle = (type: any) => {
      let approveStatus = type == 1 ? true : false;
      let paramSend = {
        procId: query.procId,
        taskId: query.taskId,
        listReciveVar: [
          {
            key: "approve",
            value: approveStatus,
          },
        ],
        option: refuseReason.value,
      };
      WorkflowApi.send(paramSend)
        .then((res: any) => {
          Utils.presentToast("审批成功");
          router.go(-1);
        })
        .catch((err: any) => {
          Utils.presentToastWarning(err);
        });
    };
    const changeisMore = () => {
      isMore.value = true;
    };

    onMounted(() => {
      initData();
    });

    const taskList = reactive([
      {
        id: "task@task(911f21d5-087e-4580-a4f1-8b4aebe7f2cb)",
        name: "98098098908",
        taskUser: [
          {
            id: "task@taskUser(029d9bee-d404-4298-b0d7-121578b5effc)",
            modifier: "李羊羊",
            modifierId: "43ff1b69e9ae4fcfbd932ad16dafca9e",
            modifyTime: "2021-03-08 10:59:52",
            name: "李羊羊",
            userId: "43ff1b69e9ae4fcfbd932ad16dafca9e",
            workType: 1,
          },
        ],
      },
    ]);

    const targetList = reactive([
      {
        id: "task@task(911f21d5-087e-4580-a4f1-8b4aebe7f2cb)",
        name: "98098098908",
        users: [
          {
            userName: "李羊羊",
          },
        ],
      },
    ]);

    return {
      title,
      btnIconRight,
      btnSizeRight,
      handleType,
      approval,
      taskList,
      targetList,
      stepList,
      isMore,
      subStringUserName,
      changeisMore,
      agreeHandle,
      canRollBack,
      onFiltClick,
      refuseHandle,
    };
  },
});
